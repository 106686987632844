<template>
    <div style="margin-left: 20px;">
        <p style="font-size: 16px;color: #5542f6;cursor: pointer;" @click="getData()">
            <a-icon type="sync" /> 刷新数据
        </p>
        <ul class="log-ul" v-if="dataSource.length > 0">
            <li v-for="item in dataSource" :key="item.id">
                <div>
                    <span class="username">{{ item.staffName }}</span>
                    <span style="font-size: 15px;">{{ formatDate(item.operationTime) }}</span>
                </div>
                <div>
                    <span style="color: #000;font-size: 16px;">·{{ item.typeName }}{{ item.fieldDescription }}</span> <br>
                    <p v-if="item.operationContent" style="font-size: 16px;margin-left: 10px;margin-top: 10px;display: inline-block;">
                      <span v-if="!isShowInfo && (item.fieldDescription == '联系方式' || item.fieldDescription == '客户' || item.fieldDescription == '联系人')">********</span>
                      <span v-else>·{{ item.operationContent }}</span>
                    </p>
                </div>
            </li>
        </ul>

        <div v-else>
            <a-empty style="margin-top: 50px;" description="暂无操作日志" />
        </div>
    </div>
</template>

<script>
import { request } from '@/utils/request'
export default {
    props:{
        selectId:{
            type:String,
            default:""
        },
        isShowInfo:{
          type:Boolean,
          default:true
        }
    },
    watch: {
        selectId: {
        immediate: true, // 立即执行一次
        handler() {
            this.getData()
        }
    }
  },
    data() {
        return {
            dataSource:[]
        }
    },
    mounted() {
      this.getData()
    },
    methods: {
        getData(){
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/customeroperationrecord/list/'+this.selectId, 'get').then(res => {
        this.dataSource = res.data.data
      })
        },
        // 格式化年月日
        formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return result;
        }
    }
}
</script>
  
<style  scoped>
    .log-ul {
        list-style: none;
        margin-top: 20px;
    }
    .log-ul li {
        /* height: 120px; */
        min-height: 130px;
        padding-left: 40px;
        border-left: 1px solid #F1ECFF;
        position: relative;
    }
    .log-ul li:last-child {
        border-left: 0;
    }
    .log-ul .username {
        position: absolute;
        left: -25px;
        top: -10px;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        border: 1px solid #5542F6;
        text-align: center;
        line-height: 50px;
        color: #5542F6;
        background: #fff;
    }
</style>
  