<template>
  <div style="display: flex;overflow: hidden;">    
  
   <a-card style="background-color: #fff;" class="rigMaxWidth">     
     <a-tabs default-active-key="1">
     <a-tab-pane key="1" tab="基本信息">
       <editForm @getSfaContactsList="getSfaContactsList" ref="editForm" :isShowEdit="false" :selectId="this.$route.query.id" :isBack="false"></editForm>
     </a-tab-pane>
     <a-tab-pane key="2" tab="通话记录" force-render>
       <sfa_history_record type="user" :selectId="this.$route.query.id"></sfa_history_record>
     </a-tab-pane>
     <a-tab-pane key="5" tab="沟通记录" force-render>
          <interact :selectId="this.$route.query.id" :isEditRules="false"></interact>
        </a-tab-pane>
     <a-tab-pane key="4" tab="拜访记录" force-render>
       <visitTable :selectId="this.$route.query.id" :isEditRules="false"></visitTable>
     </a-tab-pane>
     
     <a-tab-pane key="3" tab="操作日志">
       <customerLog :selectId="this.$route.query.id" :is-show-info="false"></customerLog>
     </a-tab-pane>
   </a-tabs>
   </a-card>
  </div>
</template>
 
 <script>
 import { request } from '@/utils/request'
 import editForm from './addForm/AdvancedForm'
 import sfa_history_record  from '@/pages/sfa_telephoneRecord/sfa_history_record'
 import visitTable  from './components/visitTable'
 import interact  from './components/interact'
 import customerLog from './components/customerLog'

 export default {
   name: 'list',
   inject: ['reload'],
   components:{editForm,sfa_history_record,customerLog,visitTable,interact},
   data() {
     return {
       selItem:{},
       selectId:"",
       isCollapsed: this.$store.state.collapsed,
       sfaContactList: [],
     }
   },
  
   mounted() {
     // this.getCity();
     // this.getData();
     // this.getUserList();
     // request(process.env.VUE_APP_API_BASE_URL + 'sfa/welfare/list', 'get').then(res => {
     //   this.welfare = res.data.data;
     //   this.welfare.forEach(item => {
     //     this.customerWelfareList.forEach((son) => {
     //       if (item.id == son.welfareId) {
     //         son.describe = item.describe
     //         this.describeText = this.describeText + item.describe + '+'
     //       }
     //     })
     //   })
     //   this.describeText = this.describeText.replace(/\+$/, '')
     // })
     // request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
     //   this.users = res.data.data;
     //   for (let i in this.users) {
     //     if (this.users[i].account == this.user.account) {
     //       this.userInfo.customerManagerIdList = [this.users[i].id]
     //       return false;
     //     }
     //   }
     // })
   },
   methods: {
     getSfaContactsList(e){
       this.sfaContactList = e;
     },
     selectListChange(e) {
       this.selectId = e.id;
       this.selItem = e;
       console.log(this.$children,'this.$childrenthis.$childrenthis.$children')
     },
     filterOption(input, option) {
       return (
         option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
       );
     },
     // 销售列表
     getUserList() {
       request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
         if (res.data.code == 200) {
           this.userData = res.data.data
         } else {
           this.$message.error(res.data.message)
         }
       })
     },
     // 选择筛选地址
     gsChange(e) {
       console.log(e, 'e')
       request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + e, 'get').then(res => {
         console.log(res, 'rese')
         // if (res.data.code == 200) {
         //   this.area = res.data.data;
         // } else {
         //   this.$message.error(res.data.message);
         // }
       })
     },
     // 重置搜索
     reset() {
       this.queryData = {
         page: 0,
         size: 10,
         retrievalType:1
       }
       this.dateValue = ""
       this.retrievalType = []
       this.getData()
     },
     hasPermission(permission) {
       return this.userPermissions.includes(permission);
     },
     onSelectChange(selectedRowKeys) {
       this.selectedRowKeys = selectedRowKeys;
     },
     addBtn() {
       this.visible = true;
       this.phone = [{ contactInformation: "", type: '1' }]
       this.wechat = [{ contactInformation: "", type: '4' }]
       this.zuoji = [{ contactInformation: "", type: '2' }]
     },
     //转移
     exportChange(type, e) {
       this.exportIdArr = [];
       if (type == 0) {
         this.exportIdArr[0] = e;
         this.exportVisible = true;
       } else {
         if (this.selectedRowKeys.length > 0) {
           this.exportVisible = true;
 
           this.selectedRowKeys.forEach(item => {
             this.exportIdArr.push(this.dataSource[item].id)
           })
         }
       }
 
 
     },
     phoneChange(e, index) {
       this.phone[index].type = ''
       this.phone[index].type = e.key;
     },
     weChatChange(e, index) {
       this.wechat[index].type = ''
       this.wechat[index].type = e.key;
     },
     zuojiChange(e, index) {
       this.zuoji[index].type = ''
       this.zuoji[index].type = e.key;
     },
     exportHandle() {
       if (this.exportIdArr.length == 0) {
         this.$message.error('请选择客户');
         return false;
       }
       if (!this.queryData.customerManagerIdList) {
         this.$message.error('请选择转移人');
         return false;
       }
       let params = []
       this.exportIdArr.forEach(item => {
         params.push({ customerId: item, staffIdList: this.queryData.customerManagerIdList })
       })
       request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/shift', 'post', params).then(res => {
         if (res.data.code == 200) {
           this.$message.success('转移成功！')
           this.exportVisible = false;
           this.queryData.customerManagerIdList = [];
           this.getData()
         } else {
           this.$message.error(res.data.message)
         }
       })
     },
     checkEmptyProperty(obj) {
       return Object.values(obj).every(val => val !== '');
     },
     // 保存
     handleOk(e) {
       e.preventDefault()
       this.$refs.ruleForm.validate(valid => {
         if (valid) {
           if (this.phone[0].contactInformation == "" && this.wechat[0].contactInformation == "" && this.zuoji[0].contactInformation == "") {
             this.$message.error('请补全联系方式')
             return false;
           }
 
           // 校验福利金额
           let fare = true;
           this.customerWelfareList.forEach((obj) => {
             for (const key in obj) {
               if (Object.prototype.hasOwnProperty.call(obj, key)) {
                 const value = obj[key];
                 if (!value && value !== 0) {
                   fare = false;
                 }
               }
             }
           });
           if (!fare) {
             this.$message.error('福利金额不可为空！');
             return false;
           }
           // 过滤空联系号码框
           let arr = this.phone.concat(this.wechat, this.zuoji);
           let mationList = arr.filter(obj => {
             for (let key in obj) {
               if (obj[key] === '') {
                 return false; // 
               }
             }
             return true;
           });
 
 
           // 过滤空地址
           let sfaContactAddressList = this.sfaContactAddressList
           sfaContactAddressList = this.sfaContactAddressList.map(obj => {
             for (let key in obj) {
               if (obj[key] === '') {
                 delete obj[key];
               }
             }
             return obj;
           }).filter(obj => Object.values(obj).some(value => value !== ''));
           let customerAddressList = this.customerAddressList;
           customerAddressList = this.customerAddressList.map(obj => {
             for (let key in obj) {
               if (obj[key] === '') {
                 delete obj[key];
               }
             }
             return obj;
           }).filter(obj => Object.values(obj).some(value => value !== ''));
           let params = {
             "name": this.userInfo.gsname,
             "type": 1,
             "scale": this.userInfo.scale,
             "enterpriseType": this.userInfo.enterpriseType,
             "remarks": this.userInfo.remarks,
             "abbreviation": this.userInfo.abbreviation,
             "source": this.userInfo.source,
             "budgetAmount": this.userInfo.budgetAmount,
             "customerManagerIdList": this.userInfo.customerManagerIdList,
             "customerWelfareList": this.customerWelfareList,
             "customerAddressList": customerAddressList,
             "sfaContactsList": [{
               "name": this.userInfo.name,
               "role": this.userInfo.role,
               "sex": this.userInfo.sex,
               "birthday": this.userInfo.birthday,
               "sfaContactInformationList": mationList,
               "sfaContactAddressList": sfaContactAddressList
             }
             ]
           }
           request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/check', 'post', params).then(res => {
             if (res.data.data) {
               this.$message.warning(res.data.data)
               return false;
             }
             this.$message.loading();
             request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/save', 'post', params).then(res => {
               this.$message.destroy()
               if (res.data.code == 200) {
                 this.$message.success('添加成功！');
                 this.visible = false;
                 this.getData();
                 this.reload();
               } else {
                 this.$message.error(res.data.message);
               }
             })
           })
         } else {
           console.log('error submit!!');
           return false;
         }
       });
 
     },
     // 添加地址
     addAddress(type, i) {
       if (type == 3 || type == 4) {
         if (type == 3) {
           this.customerAddressList.push({})
         } else {
           this.customerAddressList.splice(i, 1)
         }
         return false;
       }
       if (type == 0) {
         this.sfaContactAddressList.push({})
       } else {
         this.sfaContactAddressList.splice(i, 1)
       }
     },
     getCity() {
       request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/0', 'get').then(res => {
         if (res.data.code == 200) {
           this.prov = res.data.data;
           this.gsAddress = res.data.data;
         } else {
           this.$message.error(res.data.message)
         }
       })
     },
     provChange(id, index) {
       const selProv = this.prov.find(item => item.id === id);
       this.sfaContactAddressList[index].provinceCode = selProv.id;
       this.sfaContactAddressList[index].provinceName = selProv.name;
       this.sfaContactAddressList[index].cityCode = '';
       this.sfaContactAddressList[index].cityName = '';
       this.sfaContactAddressList[index].countyCode = '';
       this.sfaContactAddressList[index].countyName = '';
       request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
         if (res.data.code == 200) {
           this.city = res.data.data;
         } else {
           this.$message.error(res.data.message);
         }
       })
     },
     cityChange(id, index) {
       const selProv = this.city.find(item => item.id === id);
       this.sfaContactAddressList[index].cityCode = selProv.id;
       this.sfaContactAddressList[index].cityName = selProv.name;
       this.sfaContactAddressList[index].countyCode = '';
       this.sfaContactAddressList[index].countyName = '';
       request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
         if (res.data.code == 200) {
           this.area = res.data.data;
         } else {
           this.$message.error(res.data.message);
         }
       })
     },
     areaChange(id, index) {
       const selProv = this.area.find(item => item.id === id);
       this.sfaContactAddressList[index].countyCode = selProv.id;
       this.sfaContactAddressList[index].countyName = selProv.name;
     },
 
     provChange1(id, index) {
       const selProv = this.prov.find(item => item.id === id);
       this.customerAddressList[index].provinceCode = selProv.id;
       this.customerAddressList[index].provinceName = selProv.name;
       this.customerAddressList[index].cityCode = '';
       this.customerAddressList[index].cityName = '';
       this.customerAddressList[index].countyCode = '';
       this.customerAddressList[index].countyName = '';
       request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
         if (res.data.code == 200) {
           this.city = res.data.data;
         } else {
           this.$message.error(res.data.message);
         }
       })
     },
     cityChange1(id, index) {
       const selProv = this.city.find(item => item.id === id);
       this.customerAddressList[index].cityCode = selProv.id;
       this.customerAddressList[index].cityName = selProv.name;
       this.customerAddressList[index].countyCode = '';
       this.customerAddressList[index].countyName = '';
       request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
         if (res.data.code == 200) {
           this.area = res.data.data;
         } else {
           this.$message.error(res.data.message);
         }
       })
     },
     areaChange1(id, index) {
       const selProv = this.area.find(item => item.id === id);
       this.customerAddressList[index].countyCode = selProv.id;
       this.customerAddressList[index].countyName = selProv.name;
     },
     handlePageChange(page) {
       this.queryData.page = page - 1;
       this.currentPage = page;
       this.getData()
     },
     // 格式化年月日
     formatDate(timestamp) {
       const date = new Date(timestamp);
       const year = date.getFullYear();
       const month = date.getMonth() + 1;
       const day = date.getDate();
       let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
       return result
     },
     // 编辑
     editChange(id) {
       this.$router.push({
         path: '/sfa_customer/addForm',
         query: { id: id }
       });
     },
     details(id) {
       this.$router.push({
         path: '/sfa_customer/Contact',
         query: { id: id }
       });
     },
     progDeal(id) {
       this.$router.push({
         path: '/sfa_customer/InvoiceList',
         query: { id: id }
       });
     },
     hisRecord(id){
     this.$router.push({
       path: '/sfa_telephoneRecord/sfa_history_record',
       query: { id: id }
     });
   },
     // 选择日期
     dateChange(date, dateString) {
       console.log(date, dateString);
       this.queryData.startTime = dateString[0].toString();
       this.queryData.endTime = dateString[1].toString();
       this.dateValue = date
     },
     resetQuery() {
       this.queryData = {
         page: 0,
         size: 10,
         name: '',
         phone: '',
         address: '',
         dateMax: '',
         dateMin: '',
         startTime: '',
         endTime: '',
         remarks: ''
       }
       this.getData()
     },
     birthdayChange(date, dateString) {
       this.userInfo.birthday = dateString
     },
     createChange(date, dateString) {
       this.userInfo.createTime = dateString
     },
     addIpt(i) {
       if (i == 0) {
         this.phone.push({ contactInformation: "", type: "1" });
       }
       if (i == 1) {
         this.wechat.push({ contactInformation: "", type: "4" });
       }
       if (i == 2) {
         this.zuoji.push({ contactInformation: "", type: "2" });
       }
     },
     delIpt(type, i) {
       if (type == 0) {
         this.phone.splice(i, 1)
       } else if (type == 1) {
         this.wechat.splice(i, 1)
       } else {
         this.zuoji.splice(i, 1)
       }
     },
     // 获取列表 
     getData() {
       this.tabLoading = true;
       request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'post', this.queryData).then(res => {
         let data = res.data.data;
         this.tabLoading = false;
         if (res.data.code == 200) {
           this.dataSource = data.data;
           this.queryData.page = data.page;
           this.queryData.size = data.size;
           this.total = data.total;
         } else {
           this.$message.error(res.data.message)
         }
       })
 
       // 查询当日新增
       request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/countCustomer', 'post', {retrievalType:1}).then(res => {
         let data = res.data.data;
         this.countCustomerToday = data.toDayNumber;
         this.totalNumber = data.totalNumber;
 
       })
 
     },
     // 是否确认删除
     delConfirm(e) {
       this.$message.loading();
       request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/' + e.id, 'delete').then(res => {
         this.$message.destroy();
         if (res.data.code == 200) {
           this.$message.success('删除成功')
           this.getData()
         }
       })
     },
   }
 }
 </script>
 
 <style lang="less" scoped>
 .search {
   // margin-bottom: 54px;
 }
 
 .fold {
   width: calc(100% - 216px);
   display: inline-block
 }
 
 .operator {
   margin-bottom: 18px;
 }
 
 .cre-btn {
   width: 100%;
   height: 64px;
   line-height: 64px;
   background: #EEECFE;
   border-radius: 4px;
   border: 1px dashed #5542F6;
   cursor: pointer;
   font-size: 16px;
   font-family: PingFangHK-Regular, PingFangHK;
   font-weight: 400;
   color: #5542F6;
 }
 
 .btn-box button {
   width: 106px;
   height: 36px;
   margin-left: 10px;
 }
 
 
 
 .page-box {
   display: flex;
   justify-content: right;
   padding-right: 46px;
   margin-top: 10px;
 }
 
 ::v-deep .ant-card-body {
   padding-top: 0;
 }
 
 
 
 .top-text p {
   height: 10px;
 }
 
 .top-text div {
   border: 1px solid #ccc;
   border-radius: 10px;
   margin-right: 20px;
   padding: 2px 20px;
   box-sizing: border-box;
   margin-top: 20px;
 }
 
 .top-form {
   box-sizing: border-box;
 }
 
 .top-form .ant-form-item {
   margin-right: 20px !important;
 }
 
 ::v-deep .top-form .ant-col-6 {
   width: auto !important;
 }
 
 
 ::v-deep .ant-col-6 {
   width: auto !important;
 }

 .rigMaxWidth {
   height: 92vh;
   overflow-y:scroll;
 }
 .rigWidth {
   width: 60vw;
 }

 li {
 list-style: none;
}

.top-ul {
 display: flex;
 margin-top: 17px;
}
.top-ul>div {
 margin-right: 60px;
 font-size: 15px;
}
.top-ul span{
 font-family: PingFangSC, PingFang SC;
font-weight: 500;
font-size: 16px;
color: #008101;
}
.top-ul img {
 margin-right: 4px;
 width: 30px;
 height: 30px;
}
 </style>
 
